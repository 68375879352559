.post {
  background-color: lightgray;
  margin: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;

}

.postAuthor {
  padding-top: 0px;
}

input[type=text] {
  box-sizing: border-box;
  padding: 7px 10px;
}

/* this makes literally 0 sense it just inserts like 8 miles of line breaks by itself */
h3 {
  margin-bottom: -10px;
}

#allPosts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  flex-basis: min-content;
  flex-shrink: 1;
}

form {
  /* Center the form on the page */
  margin: 0 auto;
  width: 400px;
  /* Form outline */
  padding: 1em;
  border: 1px solid #CCC;
  border-radius: 1em;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

form li + li {
  margin-top: 1em;
}

label {
  /* Uniform size & alignment */
  display: inline-block;
  width: 90px;
  text-align: right;
}

input,
textarea {
  /* To make sure that all text fields have the same font settings
     By default, textareas have a monospace font */
  font: 1em sans-serif;

  /* Uniform text field size */
  width: 300px;
  box-sizing: border-box;

  /* Match form field borders */
  border: 1px solid #999;
  margin: 5px;
}

input:focus,
textarea:focus {
  /* Additional highlight for focused elements */
  border-color: #000;
}

textarea {
  /* Align multiline text fields with their labels */
  vertical-align: top;

  /* Provide space to type some text */
  height: 5em;
  width: 97.5%;

  resize: vertical;
}

.button {
  /* Align buttons with the text fields */
  padding-left: 90px; /* same size as the label elements */
}

button {
  /* This extra margin represent roughly the same space as the space
     between the labels and their text fields */
  margin-left: .5em;
}
